@tailwind base;
@tailwind components;
@tailwind utilities;

/* PACKAGES */
@import "react-phone-number-input/style.css";
@import "react-toastify/dist/ReactToastify.css";

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/Proxima-Nova/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/Proxima-Nova/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/Proxima-Nova/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/Proxima-Nova/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/Proxima-Nova/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/Proxima-Nova/ProximaNova-Extrabld.woff");
}

@font-face {
  font-family: "Helvetica";
  src: url("../assets/fonts/Helvetica/Helvetica.ttf");
}

@font-face {
  font-family: "Helvetica-Bold";
  src: url("../assets/fonts/Inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-Extrabold";
  src: url("../assets/fonts/Open-Sans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("../assets/fonts/Open-Sans/OpenSans-Bold.ttf");
}

.container {
  max-width: 155vh;
  margin: auto;
}

/* TESTIMONNIAL GRADIENT */
.testimonial-gradient {
  background: linear-gradient(0, #fff, transparent);
}

/* NEWSLETTER GRADIENT */
.newsletter-gradient {
  background-color: #4d4855;
  background-image: linear-gradient(147deg, #4d4855 0%, #000000 74%);
}

.gradient-text {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(89.95deg, #d59a4a 23.13%, #fff 40.86%);
  -webkit-background-clip: text;
  background-clip: text;
}

/* PHONE */
.PhoneInputInput {
  outline: none;
  padding-left: 1vh;
}

.PhoneInputInput::placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
  font-weight: 400;
}

.PhoneInputInput:focus {
  outline: none;
}

/* HIDE SCROLLBAR */
body::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.badge-gradient {
  background-color: #d2a813;
  background-image: linear-gradient(315deg, #d2a813 0%, #000000 74%);
}

/* HEADER */
.main-header {
  box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.2);
}

.hollow-text {
  -webkit-text-size-adjust: 0%;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  -moz-text-stroke-color: #212c40;
  -webkit-text-stroke-color: #212c40;
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-width: 1px;
}

.hollow-text.white {
  -webkit-text-size-adjust: 0%;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  -moz-text-stroke-color: #fff;
  -webkit-text-stroke-color: #fff;
  -moz-text-stroke-width: 1px;
  -webkit-text-stroke-width: 1px;
}

.feature-card {
  top: 2vh;
}

.feature-card:nth-child(n) {
  top: 6vh;
}

.banner-shape {
  transform: translateY(-15.5vh) translateX(10vh);
}

.banner-shape-bottom {
  transform: translateY(15.5vh) translateX(-10vh);
}

.banner-image {
  perspective: 1e3px;
  perspective-origin: 50% 50%;
  margin-top: 4vh;
}

.banner-image.rotate-img img {
  transform: rotateX(11.9667deg);
}

.banner-image img {
  transition: all 300ms ease-in-out;
}

@media (max-width: 575px) {
  .container {
    padding: 1.5vh;
  }

  .banner-image.rotate-img {
    transform: none;
  }
}

.Toastify__toast-body {
  font-family: "ProximaNova-Semibold", sans-serif !important;
  font-size: 1.7vh;
}

.backdrop-blur-sm {
  backdrop-filter: blur(0px);
}

.backdrop-blur-custom {
  backdrop-filter: blur(4px);
}
